<template>
  <v-row style="justify-content: center" class="time-input">
    <v-col v-if="prependIcon" class="d-inline-flex pa-2 col-auto">
      <v-icon>{{ prependIcon }}</v-icon>
    </v-col>
    <v-col class="d-inline-flex pa-0" cols="5">
      <div
        class="v-text-field colorBarrita"
        v-bind:class="{ 'error--text': error }"
      >
        <v-row class="v-input__control ma-0">
          <div class="v-input__slot">
            <the-mask
              :mask="[mask]"
              v-model="value[valueKey]"
              :tokens="hexTokens"
              :masked="true"
              class="input"
              placeholder="HH:MM"
              name="phone"
              :disabled="disabled ? disabled : false"
              @blur.native="onBlurNumber"
              @change="$emit('change')"
              style="font-size: 16px"
            ></the-mask>
          </div>
        </v-row>
        <div class="v-text-field__details">
          <div class="v-messages theme--light">
            <div class="v-messages__wrapper"></div>
          </div>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mask, TheMask } from "vue-the-mask";
export default {
  name: "TimeInput",
  props: [
    "value",
    "valueKey",
    "prependIcon",
    "readonly",
    "disabled",
    "label",
    "error",
  ],

  directives: {
    mask,
  },
  components: {
    TheMask,
  },
  data() {
    return {
      val: null,

      hexTokens: {
        H: {
          pattern: /[0-2]/,
        },
        h: {
          pattern: /[0-3]/,
        },
        "#": {
          pattern: /\d/,
        },
        m: {
          pattern: /[0-5]/,
        },
      },
    };
  },
  computed: {
    mask() {
      var mask = "H";
      if (this.value[this.valueKey]) {
        if (
          this.value[this.valueKey].substring(0, 1) === "0" ||
          this.value[this.valueKey].substring(0, 1) === "1"
        ) {
          mask += "#";
        } else if (this.value[this.valueKey].substring(0, 1) === "2") {
          mask += "h";
        }
      }
      mask += ":m#";
      return mask;
    },
  },

  methods: {
    onBlurNumber() {
      //console.log("blur");
      this.$emit("blur");
      if (this.value[this.valueKey]) {
        if (
          this.value[this.valueKey].length < 5 &&
          this.value[this.valueKey].length >= 2
        ) {
          //console.log("add");
          this.add();
        }
      }
    },

    add() {
      let val = this.value[this.valueKey];

      if (!val.includes(":")) {
        val += ":";
      }
      var length = 5;
      while (val.length < length) {
        val += "0";
      }
      this.$set(this.value, this.valueKey, val);
    },
    /*Replace numbers with comma separated value*/
    timeCheck() {
      //console.log(this.value[this.valueKey].match(this.pattern));
    },
  },
};
</script>

<style lang="sass" scoped>
.label::hover
  background-color: primary
.heightzero
  height: 0
*
  color: white !important
</style>
<style lang="sass">
.time-input
  input
    text-align: center

.colorBarrita > .v-input__control > .v-input__slot:before, .colorBarrita > .v-input__control > .v-input__slot:after
  height: 1px
  background-color: var(--v-primary-base) !important
  border-color: var(--v-primary-base) !important
.colorBarrita.error--text > .v-input__control > .v-input__slot:before, .colorBarrita.error--text > .v-input__control > .v-input__slot:after
  height: 1px
  background-color: red !important
  border-color: red !important
</style>
